import { useCallback, useState } from 'react';
import AxiosApiInstance from '../common/Interceptors';
import { URL_API_V2 } from '../constants/global';
import { useRecoilState } from 'recoil';
import { userSecretsAtom, userAtom } from '../common/Atoms';
import { notification } from 'antd';

const useEventFields = () => {
  const [eventFields, setEventFields] = useState<any[]>([]);
  const { axiosApiInstance } = AxiosApiInstance();

  const [loading, setLoading] = useState(false);
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);
  const [user] = useRecoilState(userAtom);

  const getAllFields = useCallback(() => {


    if (!user.organization) {
      notification.error({
        message: "Organization Not Selected",
        description: "Please select an organization before fetching devices.",
        key: "organization-not-selected", // Unique key to prevent duplicate notifications
      });
      return;
    }

    console.log('Fetching all fields');
    axiosApiInstance
      .get(`${URL_API_V2}/r/${user.organization}/events/fields/`)
      .then((response) => {
        if (response && response.status === 200) {
          const fields = response.data as any[];
          setEventFields(fields.map((field) => field.name));
        } else {
          setEventFields([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setEventFields([]);
      });
  }, [user.organization, axiosApiInstance]);

  return { eventFields, getAllFields };
};

export default useEventFields;
