// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import { SENTRY_DSN } from './constants/global';
import { RecoilRoot } from 'recoil';
import { QueryClientProvider } from 'react-query';
import queryClient from './react-query-client';
import { BrowserRouter as Router } from 'react-router-dom';
//import * as Sentry from '@sentry/react';
//import { BrowserTracing } from '@sentry/tracing';
// import * as Sentry from "@sentry/browser";



// let is_sentry_enabled = SENTRY_DSN ? true : false;

// if (is_sentry_enabled) {
//     Sentry.init({
//       dsn: SENTRY_DSN,
//       integrations: [new BrowserTracing()],
//       // integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],

//       // Set tracesSampleRate to 1.0 to capture 100%
//       // of transactions for performance monitoring.
//       // We recommend adjusting this value in production
//       tracesSampleRate: 1.0,
//       // attachStacktrace: true,
//       // logLevel: 1,
//     });
// }

if (process.env.NODE_ENV === 'prod') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </RecoilRoot>
  // </React.StrictMode>
);

reportWebVitals();