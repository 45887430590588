// DataUsage.tsx

import { Button, Card, Col, notification, Row } from "antd";
import { Utils } from "common/Utils";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";

interface DataItem {
  label: string;
  count: number;
  totalSize: number;
}

interface DiskUsageResponse {
  all: DataItem;
  collections: {
    [key: string]: DataItem;
  };
}

const DataUsage: React.FC = () => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);

  const [dataCategory, setDataCategory] = useState<"all" | "demo-sampling">("all");

  const [dataProps, setDataProps] = useState<{
    loading: boolean;
    hasData: boolean;
    data: DiskUsageResponse | null;
  }>({
    loading: true,
    hasData: false,
    data: null,
  });

  const [totals, setTotals] = useState<{ count: number; totalSize: number }>({ count: 0, totalSize: 0 });

  /**
   * Validates whether an object conforms to the DataItem interface.
   *
   * @param obj - The object to validate.
   * @returns True if valid, false otherwise.
   */
  const isValidDataItem = (obj: any): obj is DataItem => {
    return (
      obj &&
      typeof obj.label === "string" &&
      typeof obj.count === "number" &&
      typeof obj.totalSize === "number"
    );
  };

  /**
   * Flattens the nested DiskUsageResponse into an array of DataItems.
   *
   * @param data - The DiskUsageResponse from the API.
   * @returns An array of objects containing key and DataItem.
   */
  const flattenData = (data: DiskUsageResponse): { key: string; dataItem: DataItem }[] => {
    const flatData: { key: string; dataItem: DataItem }[] = [];

    Object.entries(data).forEach(([key, value]) => {
      if (key === "all" && isValidDataItem(value)) {
        flatData.push({ key, dataItem: value });
      } else if (key === "collections" && typeof value === "object") {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (isValidDataItem(subValue)) {
            flatData.push({ key: subKey, dataItem: subValue });
          }
        });
      }
    });

    return flatData;
  };

  /**
   * Calculates the total count and size from the collections.
   *
   * @param data - The collections object containing DataItems.
   * @returns An object with total count and total size.
   */
  const calculateTotals = (data: { [key: string]: DataItem }) => {
    return Object.values(data).reduce(
      (acc, value) => {
        acc.count += value.count;
        acc.totalSize += value.totalSize;
        return acc;
      },
      { count: 0, totalSize: 0 }
    );
  };

  /**
   * Fetches disk usage data from the API.
   */
  const getDiskUsage = async () => {
    setDataProps((prevProps) => ({ ...prevProps, loading: true }));

    try {
      const response = await axiosApiInstance.get<DiskUsageResponse>(
        `${URL_API_V1}/r/${user.organization}/disk-usage/`,
        {
          params: { 'data-type': 'all' },
        }
      );

      if (response.status === 200 && response.data) {
        setDataProps({
          loading: false,
          hasData: true,
          data: response.data,
        });
        setTotals(calculateTotals(response.data.collections));
      } else {
        console.warn(`Unexpected response status: ${response.status}`);
        setDataProps((prevProps) => ({ ...prevProps, loading: false }));
      }
    } catch (error) {
      console.error("Error fetching disk usage:", error);
      setDataProps((prevProps) => ({ ...prevProps, loading: false }));
    }
  };

  /**
   * Deletes data based on the specified category and type.
   *
   * @param dataCategory - The category of data to delete.
   * @param dataType - The type of data to delete.
   */
  const deleteData = (dataCategory: string, dataType: string) => {
    console.log("deleteData: " + dataCategory + " " + dataType);
    setDataProps((prevProps) => ({ ...prevProps, loading: true }));
    axiosApiInstance
      .delete(
        `${URL_API_V1}/r/${user.organization}/data/?data-category=${dataCategory}&data-type=${dataType}`
      )
      .then((response) => {
        if (response && response.status === 200) {
          let typeOfData = dataType === "all" ? "" : Utils.titleCase(dataType);
          notification.success({
            message: `Deleted ${dataCategory} ${typeOfData}`,
          });
          getDiskUsage();
        }
      })
      .catch((exception) => {
        console.log("deleteData.." + exception);
        setDataProps((prevProps) => ({ ...prevProps, loading: false }));
      });
  };

  useEffect(() => {
    getDiskUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If data is not yet loaded, avoid calling flattenData to prevent errors
  const flatData = dataProps.data ? flattenData(dataProps.data) : [];

  return (
    <div>
      <Card>
        <Row>
          <Col>
            <h3>Disk usage</h3>
            <p>View your disk usage and manage your data</p>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: "right", marginBottom: "15px" }}>
              {/* Uncomment and adjust the Popover and CheckableTag as needed */}
              {/* 
              <Popover
                placement="topLeft"
                arrowContent
                content="Activate filter to display only Demo-Data sampling"
              >
                <CheckableTag
                  onChange={() =>
                    setDataCategory(
                      dataCategory === "all" ? "demo-sampling" : "all"
                    )
                  }
                  key="demo-sampling"
                  checked={dataCategory === "demo-sampling"}
                >
                  Demo-Sampling
                </CheckableTag>
              </Popover> 
              */}
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {flatData.map(({ key, dataItem }) => (
            <Col key={key} xs={24} sm={12} md={8} lg={6}>
              <Card>
                <Row>
                  <Col>
                    <h4>{Utils.titleCase(dataItem.label)}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>Number of records: {dataItem.count}</Col>
                </Row>
                <Row>
                  <Col>Disk usage: {Utils.formatBytes(dataItem.totalSize)}</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={{ textAlign: "right", marginTop: "10px" }}>
                      <Button
                        onClick={() => deleteData(dataCategory, key)}
                        style={{ fontSize: "small" }}
                        type="dashed"
                        disabled={dataProps.loading} // Disable button while loading
                      >
                        Delete data
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        {dataProps.loading && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <p>Loading...</p>
          </div>
        )}
        {!dataProps.loading && flatData.length === 0 && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <p>No data available.</p>
          </div>
        )}
      </Card>
    </div>
  );
};

export default DataUsage;