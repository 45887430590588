import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as echarts from 'echarts';
import { notification } from 'antd';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import AxiosApiInstance from "../../common/Interceptors";
import { styleModeAtom, userAtom } from '../../common/Atoms';
import { useDeviceCharts } from "contexts/DeviceChartsContext";
import { useDevice } from "contexts/DeviceContext";
import { URL_API_V1 } from '../../constants/global';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT, TIMEZONE } from "constants/user";

interface DataPoint {
  x: number;
  y: number;
  src_ip: string;
  src_port: string;
  dst_ip: string;
  dst_port: string;
  field: string;
  table: number;
}

interface ScatterChartEChartsProps {
  functionName: string;
  selectedPort?: number;
}

const ScatterChartECharts: React.FC<ScatterChartEChartsProps> = ({ functionName, selectedPort }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartData, setChartData] = useState<DataPoint[]>([]);
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [themeState] = useRecoilState(styleModeAtom);
  const isDarkTheme = themeState.theme === "dark";
  const { selectedHost } = useDevice();
  const { updateChart } = useDeviceCharts();

  // Fixed aggregation to IP only
  const aggregateByIpOnly = true;

  // Define the unit and scaling factor
  type UnitType = 'KB' | 'MB';
  const UNIT: UnitType = 'MB' as UnitType; // Change to 'KB' if needed
  const SCALING_FACTOR = UNIT === 'KB' ? 1024 : 1024 * 1024; // 1024 bytes = 1 KB, 1048576 bytes = 1 MB

  const aggregateData = (data: any[], scalingFactor: number, aggregateByIpOnly: boolean): DataPoint[] => {
    if (data.length === 0) return [];

    // Parse '_start' and '_stop'
    const startTime = new Date(data[0]._start).getTime();
    const stopTime = new Date(data[0]._stop).getTime();
    const duration = stopTime - startTime;

    // Get unique 'table' values and sort them
    const tableValues = Array.from(new Set(data.map(item => item.table))).sort((a, b) => a - b);
    const tableMap = new Map(tableValues.map((value, index) => [value, index]));

    const totalTables = tableValues.length - 1 || 1; // Avoid division by zero

    const aggregatedData: Record<string, DataPoint> = {};

    data.forEach((item) => {
      // Compute time based on table index
      const tableIndex = tableMap.get(item.table) || 0;
      const timeFraction = tableIndex / totalTables;
      const time = startTime + timeFraction * duration;

      // Determine the key for aggregation (IP Only)
      const key = `${item.src_ip}-${item.dst_ip}-${item._field}`;

      if (aggregatedData[key]) {
        aggregatedData[key].y += Number(item._value);
      } else {
        aggregatedData[key] = {
          x: time,
          y: Number(item._value),
          src_ip: item.src_ip,
          src_port: item.src_port,
          dst_ip: item.dst_ip,
          dst_port: item.dst_port,
          field: item._field,
          table: item.table
        };
      }
    });

    return Object.values(aggregatedData).map((point) => ({
      ...point,
      y: point.y / scalingFactor,
    }));
  };

  const fetchData = useCallback(async () => {
    const baseApi = `${URL_API_V1}/r/${user.organization}/metrics/devices`;
    let apiUrl = baseApi;

    if (selectedHost) {
      apiUrl = `${baseApi}/${selectedHost}`;
    }

    if (!user.organization) {
      notification.error({
        message: "Organization Not Selected",
        description: "Please select an organization before fetching devices.",
        key: "organization-not-selected", // Unique key to prevent duplicate notifications
        showProgress: true,
      });
      return;
    }

    try {
      const response = await axiosApiInstance.get(apiUrl);

      if (response && response.status === 200) {
        const data = response.data;
        const aggregatedData = aggregateData(data, SCALING_FACTOR, aggregateByIpOnly);
        setChartData(aggregatedData);
      } else {
        notification.error({
          message: "Error fetching device metrics data",
          description: "Error fetching device metrics data",
          key: "error-fetch-device-metrics",
          duration: 2,
          showProgress: true,
        });
      }
    } catch (error) {
      notification.error({
        message: "Error fetching device metrics data",
        description: "Error fetching device metrics data",
        key: "error-fetch-device-metrics",
        duration: 2,
        showProgress: true,
      });
    }

  }, [selectedHost, selectedPort, user.organization, SCALING_FACTOR, aggregateByIpOnly]);

  useEffect(() => {
    fetchData();
  }, [updateChart, fetchData]);

  useEffect(() => {
    if (!chartRef.current) return;
    const myChart = echarts.init(chartRef.current, isDarkTheme ? 'dark' : 'light');

    const options: echarts.EChartsOption = {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const data = params.data;
          const valueOriginal = data.y * SCALING_FACTOR; // Convert back to bytes
          const valueFormatted = UNIT === 'KB'
            ? (valueOriginal / 1024).toFixed(2)
            : (valueOriginal / (1024 * 1024)).toFixed(2);
          return `
            <div>
              <div><strong>${data.field.toUpperCase()}</strong></div>
              <div>Time: ${moment(data.x).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT)}</div>
              <div>Source: ${data.src_ip}</div>
              <div>Destination: ${data.dst_ip}</div>
              <div>Value: ${valueOriginal.toFixed(2)} bytes (${valueFormatted} ${UNIT})</div>
            </div>
          `;
        }
      },
      xAxis: {
        type: 'time',
        name: 'Time',
        axisLabel: {
          formatter: (value: number) => moment(value).format(DEFAULT_TIME_FORMAT),
          rotate: 0,
        },
        splitLine: {
          show: true,
        },
        splitNumber: 6,
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: {
        type: 'value',
        name: `Value (${UNIT})`,
        axisLabel: {
          formatter: (value: number) => `${value} ${UNIT}`
        }
      },
      series: [
        {
          name: 'rx',
          type: 'scatter',
          data: chartData.filter(point => point.field === 'rx').map(point => ({
            ...point,
            value: [point.x, point.y]
          })),
          symbol: 'circle',
          itemStyle: {
            color: '#ff9a9e'
          }
        },
        {
          name: 'tx',
          type: 'scatter',
          data: chartData.filter(point => point.field === 'tx').map(point => ({
            ...point,
            value: [point.x, point.y]
          })),
          symbol: 'diamond',
          itemStyle: {
            color: '#a18cd1'
          }
        }
      ],
      legend: {
        data: ['rx', 'tx'],
        textStyle: {
          color: isDarkTheme ? '#fff' : '#000'
        }
      }
    };

    myChart.setOption(options);

    const resizeHandler = () => {
      myChart.resize();
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      myChart.dispose();
    };
  }, [chartData, isDarkTheme, SCALING_FACTOR, UNIT, aggregateByIpOnly]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div ref={chartRef} style={{ width: '100%', height: 300 }} />
    </div>
  );
};

export default ScatterChartECharts;

