import React, { useState, useEffect } from 'react';
import { Badge, Button, Drawer, List, Alert, Spin, Tag } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { userAtom, notificationsRefreshAtom } from '../../common/Atoms';
import AxiosApiInstance from '../../common/Interceptors';
import { URL_API_V1 } from '../../constants/global';
import { DEFAULT_DATETIME_FORMAT, TIMEZONE } from '../../constants/user';
import moment from 'moment';
import styles from './Notifications.module.scss';

interface NotificationsProps {
  onSetupRequired: (uuid: string) => void;
  onLimitationsNotice: (uuid: string) => void;
}

interface Notification {
  uuid: string;
  title: string;
  message: string;
  importance: "critical" | "high" | "warning" | "info";
  notification_type: string;
  is_dismissible: boolean;
  requires_action: boolean;
  dismiss_on_action: boolean;
  requires_completion: boolean;
  created_at: string;
  start_time: string;
  end_time: string | null;
  notification_state: string;
  notification_name: string;
  state: string;
  interacted_at: string | null;
  first_time_seen: string | null;
}

const Notifications: React.FC<NotificationsProps> = ({
  onSetupRequired,
  onLimitationsNotice,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [refreshCount] = useRecoilState(notificationsRefreshAtom);

  const fetchNotifications = async () => {
    if (!user.organization) {
      console.warn('User organization is not set. Skipping fetchNotifications.');
      return;
    }

    setLoadingNotifications(true);
    setError(null);
    try {
      const response = await axiosApiInstance.get<{ count: number; results: Notification[] }>(
        `${URL_API_V1}/r/${user.organization}/user-notifications`
      );
      setNotifications(response.data.results);
    } catch (err) {
      console.error('Error fetching notifications:', err);
      setError('Failed to load notifications.');
    } finally {
      setLoadingNotifications(false);
    }
  };

  useEffect(() => {
    if (user.organization) {
      fetchNotifications();
    } else {
      // Optionally, you can reset notifications or handle UI state when organization is not set
      setNotifications([]);
    }
  }, [user.organization, refreshCount]);

  const totalUnreadNotificationCount = notifications.filter(
    (notification) => notification.state === 'unread' || (notification.requires_completion && notification.state !== 'completed')
  ).length;

  const handleNotificationAction = async (notification: Notification) => {
    if (!user.organization) {
      console.warn('User organization is not set. Cannot perform notification action.');
      return;
    }

    if (notification.requires_action) {
      if(notification.state === 'unread') {
        try {
          await axiosApiInstance.patch(
            `${URL_API_V1}/r/${user.organization}/user-notifications/${notification.uuid}`,
            { state: "read" }
          );
          fetchNotifications();
        } catch (error) {
          console.error("Failed to update notification:", error);
        }
      }

      if (notification.notification_name === 'license_limitations' && notification.state !== 'completed') {
        onLimitationsNotice(notification.uuid);
      } else if (notification.notification_name === 'complete_setup' && notification.requires_completion) {
        onSetupRequired(notification.uuid);
      }
    }
  };

  const handleDismissNotification = async (notification: Notification) => {
    if (!user.organization) {
      console.warn('User organization is not set. Cannot dismiss notification.');
      return;
    }

    try {
      await axiosApiInstance.patch(
        `${URL_API_V1}/r/${user.organization}/user-notifications/${notification.uuid}`,
        { state: "dismissed" }
      );
      fetchNotifications();
    } catch (error) {
      console.error("Failed to dismiss notification:", error);
    }
  };

  const NotificationsList = () => {
    if (loadingNotifications) {
      return (
        <div className={styles.loadingContainer}>
          <Spin tip="Loading Notifications..." />
        </div>
      );
    }

    if (error) {
      return (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      );
    }

    if (notifications.length === 0) {
      return (
        <Alert
          message="No Notifications"
          description="You have no new notifications."
          type="info"
          showIcon
        />
      );
    }

    const sortedNotifications = [...notifications].sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    return (
      <List
        itemLayout="vertical"
        dataSource={sortedNotifications}
        renderItem={item => (
          <List.Item
            key={item.uuid}
            className={styles.notificationItem}
          >
            <List.Item.Meta
              title={
                <div className={styles.titleSection}>
                  <Tag color={
                    item.importance === "critical" ? "red" :
                    item.importance === "high" ? "orange" :
                    item.importance === "warning" ? "gold" : "blue"
                  }>
                    {item.importance.charAt(0).toUpperCase() + item.importance.slice(1)}
                  </Tag>
                  <span className={styles.title}>{item.title}</span>
                  {item.state === "unread" && (
                    <Tag color="green">New</Tag>
                  )}
                </div>
              }
              description={
                <div className={styles.messageSection}>
                  <span>{item.message}</span>
                  <div className={styles.timestamp}>
                    {moment(item.created_at).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT)}
                  </div>
                </div>
              }
            />
            <div className={styles.actionSection}>
              <Tag color={(() => {
                const isCompleted = item.requires_completion 
                  ? item.state === "completed"
                  : ["completed", "dismissed", "acknowledged"].includes(item.state);
                return isCompleted ? "success" : "orange"
              })()}>
                {(() => {
                  const isCompleted = item.requires_completion 
                    ? item.state === "completed"
                    : ["completed", "dismissed", "acknowledged"].includes(item.state);
                  return isCompleted ? "Done" : "To do";
                })()}
              </Tag>
              {item.is_dismissible && (
                <Button type="link" onClick={() => handleDismissNotification(item)}>
                  Dismiss
                </Button>
              )}
              {item.requires_action && (item.state === "read" || item.state === "unread") && (
                <Button type="link" onClick={() => handleNotificationAction(item)}>
                  Take Action
                </Button>
              )}
            </div>
          </List.Item>
        )}
      />
    );
  };

  return (
    <>
      <div 
        className={styles.notificationBadge}
        onClick={() => setIsDrawerOpen(true)}
      >
        <Badge 
          count={totalUnreadNotificationCount}
          size="small"
        >
          <NotificationOutlined className={styles.notificationIcon} />
        </Badge>
      </div>

      <Drawer
        title="Notifications"
        placement="right"
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        width={400}
      >
        {/* Optionally, you can show a message if organization is not set */}
        {!user.organization ? (
          <Alert
            message="Organization Not Set"
            description="Your organization information is missing. Please contact support."
            type="warning"
            showIcon
          />
        ) : (
          <NotificationsList />
        )}
      </Drawer>
    </>
  );
};

export default Notifications;