import {
  UserOutlined,
  SunOutlined,
  MoonOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Button, Col, Layout, Row, Switch } from "antd";
import LogoComponent from "common/LogoComponent";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { styleModeAtom, currentMenuItemAtom } from "../../common/Atoms";
import OrganizationMenu from "../../common/OrganizationMenu";
import { useTheme } from "../../common/ThemeSwitcherContext";
import { useState } from "react";
import styles from "./TopBar.module.scss";
import LimitationsNotice from "../LimitationsNotice/LimitationsNotice";
import SetupWizard from "components/SetupWizard/SetupWizard";
import Notifications from "components/Notifications/Notifications";
import { SetupWizardProvider } from "components/SetupWizard/SetupWizardContext";
import HandleOrganization from "components/Organizations/HandleOrganization";

const MainHeader = () => {
  const { theme, toggleTheme } = useTheme();
  const { Header } = Layout;
  const [themeState, setThemeState] = useRecoilState(styleModeAtom);
  const navigation = useNavigate();
  const [currentMenuItem, setCurrentMenuItemAtom] =
    useRecoilState(currentMenuItemAtom);

  // State for modals
  const [isLimitationsNoticeOpen, setIsLimitationsNoticeOpen] = useState(false);
  const [setupWizardVisible, setSetupWizardVisible] = useState(false);
  const [notificationUuid, setNotificationUuid] = useState<string | null>(null);

  const handleClick = () => {
    console.log("handleClick");
    navigation("/profile");
  };

  const toggleNavbar = () => {
    setCurrentMenuItemAtom({
      ...currentMenuItem,
      collapsed: !currentMenuItem.collapsed,
    });
  };

  const toolTipText = "Toggle light/dark mode";

  const handleSetupRequired = (uuid: string) => {
    setNotificationUuid(uuid);
    setSetupWizardVisible(true);
  };

  const handleLimitationsNotice = (uuid: string) => {
    setNotificationUuid(uuid);
    setIsLimitationsNoticeOpen(true);
  };

  const handleSetupComplete = () => {
    setSetupWizardVisible(false);
  };

  return (
    <>
    <Header className={styles.topBar}>
      <Row className={styles.row}>
        {/* Menu Button */}
        <Col className={`${styles.col} ${styles.noneFlex}`}>
          <div className={styles.menuButton}>
            <Button
              shape="circle"
              type="default"
              icon={<MenuOutlined />}
              onClick={toggleNavbar}
            />
          </div>
        </Col>

        {/* Logo */}
        <Col className={`${styles.col} ${styles.noneFlex}`}>
          <div className={styles.logo}>
            <LogoComponent />
          </div>
        </Col>

        {/* Organization Menu */}
        <Col className={`${styles.col} ${styles.autoFlex}`}>
          <OrganizationMenu />
        </Col>

        {/* Theme Switch */}
        <Col className={styles.col}>
          <Switch
            title={toolTipText}
            checkedChildren={<MoonOutlined />}
            unCheckedChildren={<SunOutlined />}
            checked={theme === "dark"}
            onChange={toggleTheme}
            className={`${styles.themeSwitch} ${
              theme === "dark" ? styles.dark : styles.light
            }`}
          />
        </Col>

        {/* Notifications */}
        <Col className={`${styles.col} ${styles.noneFlex}`}>
          <div className={styles.notificationsWrapper}>
            <Notifications
              onSetupRequired={handleSetupRequired}
              onLimitationsNotice={handleLimitationsNotice}
            />
          </div>
        </Col>

        {/* User Profile Icon */}
        <Col className={`${styles.col} ${styles.noneFlex}`}>
          <div className={styles.userProfileWrapper}>
            <Button
              title="User Profile"
              size="middle"
              type="default"
              shape="circle"
              onClick={handleClick}
            >
              <UserOutlined />
            </Button>
          </div>
        </Col>
      </Row>

      {/* Modals */}
      <LimitationsNotice
        open={isLimitationsNoticeOpen}
        notificationUuid={notificationUuid || ""}
        onClose={() => setIsLimitationsNoticeOpen(false)}
      />

      <SetupWizardProvider>
        <SetupWizard
          visible={setupWizardVisible}
          onComplete={handleSetupComplete}
          onCancel={() => setSetupWizardVisible(false)}
          notificationUuid={notificationUuid || undefined}
        />
      </SetupWizardProvider>
    </Header>
    <HandleOrganization />
    </>
  );
};

export default MainHeader;
