import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "./Atoms";
import { useTwoFactorAuthCheck } from "../common/TwoFactorAuthCheck";

interface PrivateRouteProps {
  element: React.ComponentType<any>;
  [key: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Element, ...rest }) => {
  const user = useRecoilValue(userAtom);

  // This hook will redirect if 2FA is required but not completed.
  useTwoFactorAuthCheck();

  // If user is not authenticated at all, redirect to /login
  if (!user.is_authenticated) {
    return <Navigate to="/login" replace />;
  }

  // If we got here, user is authenticated, and either 2FA is not required or has been handled by the hook.
  return <Element {...rest} />;
};

export default PrivateRoute;