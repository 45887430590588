import { LockOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Form, Input, Layout, Row } from "antd";
import axios from "axios";
import { Utils } from "common/2FAUtils";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1, reCAPTCHAKEY } from "../../constants/global";
import useAuth from "../../hooks/auth";
import AuthorizationHeader from "../../hooks/authHeaders";
import styles from "./TwoFA.module.scss";

const { Content } = Layout;

function TwoFALogin() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  let navigate = useNavigate();
  const { axiosApiInstance } = AxiosApiInstance();
  const { handleLogout } = useAuth();

  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [useEmergencyCodes, setUseEmergencyCodes] = useState(false);

  const [form] = Form.useForm();

  const [user, setUser] = useRecoilState(userAtom);

  const { getAuthHeader } = AuthorizationHeader();
  const headers = getAuthHeader()["headers"];
  const data = { headers: headers };

  const handleSubmit = useCallback(
    (pForm, useEmergencyCode, recaptchaToken) => {
      let path = useEmergencyCode ? "/auth/totp/static/login/" : "/auth/totp/login/";
      pForm.validateFields().then((values) => {
        setErrorMessage("");
        const token = values["token"];
        let data = { response: recaptchaToken };
        console.log(data);

        axiosApiInstance
          .post(`${URL_API_V1}${path}${token}`, data)
          .then((response) => {
            console.log("TwoFALogin() handleSubmit() response");
            if (response) {
              if (response.status === 200) {
                setConfirmationMessage("Token valid...");
                const responseData: any = response.data;
                const token = responseData["token"];
                const default_organization = responseData["default_organization"];
                let jwt_decoded: any = jwtDecode(token);
                const is_2fa_authenticated = Utils.isJWT2FAAuthenticated(jwt_decoded);
                setUser({
                  ...user,
                  token: token,
                  is_2fa_authenticated: is_2fa_authenticated,
                  organizations: jwt_decoded["organizations"],
                  organization: default_organization,
                });
                navigate("/");
                return;
              } else if (response.status === 401) {
                // unauthorized - jwt token expired
                handleLogout();
              }
            }
            handleLogout();
          })
          .catch((err) => {
            console.log("err");
            if (err && err.response) {
              if (err.response.status === 401) {
                // unauthorized - jwt token expired
                handleLogout();
              } else if (err.response.status === 498) {
                setErrorMessage(
                  `Authentication failed. Invalid challenge-response test, we could not determine if you are a human. Please try again.`
                );
              } else {
                setErrorMessage("Invalid TOTP code.");
              }
            } else {
              setErrorMessage("An unexpected error has occurred");
            }
          });
      });
    },
    [axiosApiInstance, handleLogout, setUser, user]
  );

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (pForm, useEmergencyCode) => {
      let token = ''
      if(reCAPTCHAKEY) {
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          return;
        }
        token = await executeRecaptcha("login");
      }
      
      handleSubmit(pForm, useEmergencyCode, token);
    },
    [handleSubmit, executeRecaptcha]
  );

  const checkIsUserAlready2FA = () => {
    if (user) {
      //  note: we are not using AxiosApiInstance on purpose because we want plain simple authentication
      axios
        .get(`${URL_API_V1}/auth/totp/check`, data)
        .then((response) => {
          if (response) {
            if (response.status === 200) {
              navigate("/");
            } else if (response.status === 401) {
              handleLogout();
              navigate("/login");
            }
          } else {
            handleLogout();
          }
        })
        .catch((error) => {
          console.log("catched error.");
          console.log(error);
          if (error && error.response.status === 401) {
            // unauthorized - jwt token expired
            handleLogout();
          } else {
          }
        });
    } else {
      handleLogout();
    }
  };

  const toggleEmergencyMode = () => {
    setUseEmergencyCodes(!useEmergencyCodes);
  };

  const backToLogin = (
    <Button type="link" onClick={handleLogout}>
      Back to login
    </Button>
  );

  const basic2FAMode = (
    <>
      <Button type="link" onClick={toggleEmergencyMode}>
        Lost your device 2FA? Try emergency backup codes.
      </Button>
      {backToLogin}
    </>
  );

  const emergency2FAMode = (
    <>
      <Button type="link" onClick={toggleEmergencyMode}>
        Return to normal 2FA mode.
      </Button>
      {backToLogin}
    </>
  );

  useEffect(() => {
    checkIsUserAlready2FA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="" style={{}}>
      {/* <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHAKEY}> */}
      <Card title="Two-Factor Authentication (2FA)">
        {useEmergencyCodes ? <h4>One-time emergency backup codes</h4> : ""}
            <div className={styles.TwoFA} style={{}}>
              <Form className="login-form" form={form} onFinish={() => handleReCaptchaVerify(form, useEmergencyCodes)}>
                <Form.Item
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: "Please a valid token!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={useEmergencyCodes ? "Enter the emergency code" : "Enter the 6-digit token"}
                  />
                </Form.Item>

                <Row>
                  <Col span={16} offset={4}>
                    <div style={{ textAlign: "center", margin: "5px" }}>
                      <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                {errorMessage.length > 0 && <Alert message={errorMessage} type="error" showIcon closable />}
                {confirmationMessage && <Alert message={confirmationMessage} type="success" showIcon closable />}
              </Form>
            </div>
            {useEmergencyCodes ? emergency2FAMode : basic2FAMode}
      </Card>
      {/* </GoogleReCaptchaProvider> */}
    </div>
  );
}

export default TwoFALogin;
