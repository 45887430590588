import { Button, Result, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AxiosApiInstance from '../../common/Interceptors';
import { URL_API_V1 } from '../../constants/global';

import styles from './AcceptInvitation.module.scss';
import { ResultStatusType } from 'antd/lib/result';

const AcceptInvitation = () => {
  const { axiosApiInstance } = AxiosApiInstance();
  let { slug, token } = useParams();
  const navigate = useNavigate();

  const STATUS_CODES = {
    VALIDATING: 'VALIDATING',
    TOKEN_VALID: 'TOKEN_VALID',
    TOKEN_NOT_VALID: 'TOKEN_NOT_VALID',
    USER_ALREADY_EXISTS_IN_ORGANIZATION: 'USER_ALREADY_EXISTS_IN_ORGANIZATION',
    USER_ACCOUNT_DOES_NOT_EXIST: 'USER_ACCOUNT_DOES_NOT_EXIST',
    UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  };

  const [currentStatus, setCurrentStatus] = useState('');
  const [message, setMessage] = useState('');
  const [resultStatus, setResultStatus] = useState<ResultStatusType>('info');
  const [resultTitle, setResultTitle] = useState('');

  //const history = useHistory();

  const routeToLogin = () => {
    let path = `/login`;
    navigate(path);
  };

  const routetoRegisterChange = () => {
    let path = `/register`;
    navigate(path);
  };

  const tokenValidation = useCallback(() => {
    axiosApiInstance
      .patch(`${URL_API_V1}/r/${slug}/users/invite/${token}`)
      .then((resp) => {
        console.log(resp.data);
        if (resp.status === 200) {
          setCurrentStatus(STATUS_CODES.TOKEN_VALID);
        } else {
          console.log('else error messagee:', resp.data);
          setCurrentStatus(STATUS_CODES.UNEXPECTED_ERROR);
        }
      })
      .catch((err) => {
        console.log("catch..", err.response.data)
        if ('error_code' in err.response.data) {
          setCurrentStatus(err.response.data['error_code'])
        } else {
          setCurrentStatus(STATUS_CODES.UNEXPECTED_ERROR);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    tokenValidation();
  }, [tokenValidation]);

  useEffect(() => {
    setResultStatus(currentStatus === STATUS_CODES.TOKEN_VALID ? 'success' : 'error');
    setResultTitle(currentStatus === STATUS_CODES.TOKEN_VALID ? 'Invitation succeeded' : 'Invitation failed');

    switch (currentStatus) {
      case STATUS_CODES.TOKEN_VALID: {
        setMessage(`You have accepted the invitation, you can now access the organization '${slug}'`);
        break;
      }
      case STATUS_CODES.TOKEN_NOT_VALID: {
        setMessage(`Provided token has expired or is not valid. Please request the invitation once again.`);
        break;
      }
      case STATUS_CODES.USER_ACCOUNT_DOES_NOT_EXIST: {
        setMessage(`User does not exist, please register the account first, and then accept the invitation.`);
        break;
      }
      case STATUS_CODES.USER_ALREADY_EXISTS_IN_ORGANIZATION: {
        setMessage(`User is already part of the organization: '${slug}'`);
        break;
      }
      case STATUS_CODES.UNEXPECTED_ERROR: {
        setMessage(`Unexpected error has occured. Please contact support`);
        break;
      }
      default: {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  const getResultStatus = () => {
    if (currentStatus === STATUS_CODES.VALIDATING) {
      return (
        <Space>
          <div>Please wait..validating</div>
        </Space>
      );
    } else if (currentStatus === STATUS_CODES.TOKEN_VALID) {
      return (
        <Space>
          <Result
            status={resultStatus}
            title={resultTitle}
            subTitle={message}
            extra={
              <Button key="home" type="primary" onClick={routeToLogin}>
                Login
              </Button>
            }
          />
        </Space>
      );
    } else if (currentStatus === STATUS_CODES.USER_ACCOUNT_DOES_NOT_EXIST) {
      return (
        <Space>
          <Result
            status={resultStatus}
            title={resultTitle}
            subTitle={message}
            extra={
              <Button key="home" type="primary" onClick={routetoRegisterChange}>
                Register
              </Button>
            }
          />
        </Space>
      );
    } else {
      return (
        <Space>
          <Result
            status={resultStatus}
            title={resultTitle}
            subTitle={message}
            extra={
              <Button key="home" type="primary" onClick={routeToLogin}>
                Login
              </Button>
            }
          />
        </Space>
      );
    }
  };

  return (
    <div className={styles.AcceptInvitation}>
      {getResultStatus()}
    </div>
  );
};

export default AcceptInvitation;
