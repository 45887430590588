import { CheckCircleTwoTone, DownOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Layout, Modal, notification, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import useAuth from "../../hooks/auth";
import ModalAddOrganization from "./ModalAddOrganization";
import { TIMEZONE, DEFAULT_DATETIME_FORMAT } from "../../constants/user";
import styles from "./ModalAddOrganization.module.scss";
import moment from "moment";

export default function HandleOrganization() {
  const { Header } = Layout;

  const { axiosApiInstance } = AxiosApiInstance();

  const [user, setUser] = useRecoilState(userAtom);

  const [menuItems, setMenuItems] = useState<any>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOkDisabled] = useState(true);
  const [isCancelDisabled] = useState(true);

  const [showAddOrganization, setShowAddOrganization] = useState(false);

  const { handleLogout } = useAuth();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [propsHandleOrganization, setPropsHandleOrganization] = useState({
    loading: true,
    hasData: false,
    data: [],
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => {
        return text;
      },
    },
    {
      title: "Slug",
      dataIndex: "slug",
      render: (text) => {
        return text;
      },
    },
    {
      title: "Owner",
      dataIndex: "owner",
      render: (text) => {
        return text;
      },
    },
    {
      title: "Created on",
      dataIndex: "created_on",
      render: (raw, text) => {
        return text ? moment(text).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT) : "";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      search: false,
      render: (text, row) => (
        <>
          <Space>
            {user.organization !== row.slug ? (
              <Button
                type="link"
                onClick={() => {
                  setDefaultOrganization(row.slug);
                }}
              >
                {" "}
                <Space>Set as default</Space>
              </Button>
            ) : (
              <Space></Space>
            )}
          </Space>
        </>
      ),
    },
  ];

  async function getUserProfile() {
    return axiosApiInstance
      .get(`${URL_API_V1}/profile`)
      .then((response: any) => {
        if (response && response.status === 200) {
          let organization = response.data["default_organization_slug"];
          setUser({ ...user, organization: organization });
        }
      })
      .catch((exception) => {
        console.error("exception getUserProfile.." + exception);
      });
  }

  const getOrganizationBySlug = (organization_slug) => {
    let match_found = null;
    propsHandleOrganization.data.forEach((item) => {
      if (item["slug"] === organization_slug) {
        match_found = item;
      }
    });

    return match_found;
  };

  useEffect(() => {
    getOrganizations();
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultOrganization = (organization_slug) => {
    let selectedOrganization: any = getOrganizationBySlug(organization_slug);
    const newDefaultOrgToUpdate = { default_organization: selectedOrganization.uuid };
    axiosApiInstance.patch(`${URL_API_V1}/profile/${user.uuid}/`, newDefaultOrgToUpdate).then((response) => {
      //const data: any = response.data;
      if (response.status === 200) {
        notification.success({
          message: `The organization '${organization_slug}' has been set as default.`,
          duration: 1.5,
          showProgress: true,
        });
        getUserProfile();
      } else {
        notification.error({
          message: "The update failed - an unexpected error has occurred.",
        });
      }
    });
  };

  function getOrganizations() {
    setPropsHandleOrganization({ ...propsHandleOrganization, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/organizations/`)
      .then((response) => {
        setPropsHandleOrganization({ ...propsHandleOrganization, loading: false });
        let data = [];
        if (response) {
          if (response.status === 200) {
            const responseData: any = response.data;
            data = responseData["results"];
          }
        }
        let organizations = data.map((item) => item["slug"]);
        if (JSON.stringify(user.organizations) !== JSON.stringify(organizations)) {
          setUser({ ...user, organizations: organizations });
        }
        setPropsHandleOrganization({ ...propsHandleOrganization, data: data });
      })
      .catch((exception) => {
        console.error("test.." + exception);
      });
  }

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === "Escape") {
        setIsModalVisible(true);
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let items = user.organizations.map((item: any) => ({ key: item, label: item, onClick: handleMenuClick }));
    setMenuItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!user.organization) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [user]);

  const handleMenuClick = (event) => {
    setDefaultOrganization(event.key);
    setUser({ ...user, organization: event.key });
    window.location.reload()
  }

  useEffect(() => {
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.organizations]);

  return (
    <>
      <Modal
        title="Select an organization"
        className={styles.AddOrganizations}
        closable={false}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: isCancelDisabled }}
        okButtonProps={{ disabled: isOkDisabled }}
        maskClosable={false}
        width={800}
        centered
        keyboard={false}
        footer={[
          <Button key="back" disabled={isCancelDisabled} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" disabled={isOkDisabled} type="primary" onClick={handleOk}>
            Ok
          </Button>,
          <Button key="submit" onClick={handleLogout}>
            Logout
          </Button>,
        ]}
      >
        <Card>
          <Row>
            <Col style={{ width: "100%" }}>
              <div style={{ margin: "1em", textAlign: "right" }}>
                <Button
                  style={{ marginBottom: "1em", textAlign: "right" }}
                  htmlType="button"
                  type="primary"
                  onClick={() => setShowAddOrganization(!showAddOrganization)}
                >
                  Add organization
                </Button>
                {
                  <Table
                    key="id"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          console.log("clicked event.." + JSON.stringify(record));
                          // setSelectedOrganization(record)
                          //setDefaultOrganization(record)
                        }, // click row
                      };
                    }}
                    rowKey="id"
                    pagination={false}
                    columns={columns}
                    dataSource={propsHandleOrganization.data}
                    scroll={{ y: 350 }}
                  />
                }
              </div>
            </Col>
          </Row>
          Steps:
          <ol>
            <li>
              Create an organization{" "}
              {propsHandleOrganization?.data?.length ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                <CheckCircleTwoTone twoToneColor="#c4341a" />
              )}
            </li>
            <li>
              Mark the desired organization as default{" "}
              {user.organization ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                <CheckCircleTwoTone twoToneColor="#c4341a" />
              )}
            </li>
          </ol>
        </Card>
      </Modal>
      <ModalAddOrganization
        isShowing={showAddOrganization}
        hide={() => setShowAddOrganization(!showAddOrganization)}
        getOrganizations={getOrganizations}
      />
    </>
  );
}
