// ModalAddOrganization.tsx
import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Layout, Modal, notification, Row } from "antd";
import { useState } from "react";
import FullScreenSpinner from "../../common/FullScreenSpinner";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import useAuth from "../../hooks/auth";

const { Footer, Content } = Layout;

export default function ModalAddOrganization(props) {
  const { refreshToken } = useAuth();
  const { axiosApiInstance } = AxiosApiInstance();
  const [hasAddOrganizationErrors, setHasAddOrganizationErrors] = useState("");
  const [form] = Form.useForm();

  const [propsAddOrganization, setPropsAddOrganization] = useState({
    loading: false,
    hasData: false,
  });

  const cancelAddOrganization = () => {
    form.resetFields();
    setHasAddOrganizationErrors("");
    props.hide();
  };

  const addOrganization = async () => {
    setPropsAddOrganization({ ...propsAddOrganization, loading: true });

    try {
      const values = await form.validateFields();
      const data = { name: values["name"] };

      setHasAddOrganizationErrors("");

      const response = await axiosApiInstance.post(`${URL_API_V1}/organizations/`, data);

      if (response.status === 201) {
        form.resetFields();
        props.hide();
        // Re-fetch the organizations and user profile
        await props.getOrganizations();
        await refreshToken();
        await props.getUserProfile(); // Important: Update user profile so OrganizationMenu sees the new org
        notification.success({ message: 'Organization added successfully.' });
      } else {
        console.log("Create failed..");
        notification.error({ message: 'Failed to add organization.' });
      }
    } catch (err: any) {
      console.log("An error occurred..", err);
      if (err.response?.data?.reason) {
        setHasAddOrganizationErrors(err.response.data.reason);
      } else if (err.name === 'Error') { // Validation errors
        // Do nothing; errors are already handled by form
      } else {
        setHasAddOrganizationErrors("An unexpected error has occurred");
        notification.error({ message: 'An unexpected error has occurred.' });
      }
    } finally {
      setPropsAddOrganization({ ...propsAddOrganization, loading: false });
    }
  };

  return (
    <div>
      {propsAddOrganization.loading ? <FullScreenSpinner /> : null}
      <Modal
        title="Add organization"
        centered
        maskClosable={false}
        open={props.isShowing}
        width={500}
        onOk={addOrganization}
        onCancel={cancelAddOrganization}
        footer={[
          <Button key="back" onClick={cancelAddOrganization}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={addOrganization}
          >
            Add
          </Button>,
        ]}
      >
        <Content>
          <Form form={form}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Organization name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the organization name!",
                    },
                  ]}
                >
                  <Input placeholder="Organization name" maxLength={40} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
        <Content>
          {hasAddOrganizationErrors.length > 0 && (
            <Alert type="error" message={hasAddOrganizationErrors} banner />
          )}
        </Content>
      </Modal>
    </div>
  );
}
