import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { TWOFA_LOGIN_PATH } from "../constants/urls";
import { userAtom } from "./Atoms";

export function useTwoFactorAuthCheck() {
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    if (user && user.is_2fa_activated && user.is_2fa_authenticated === false) {
      navigate(TWOFA_LOGIN_PATH);
    }
  }, [user, navigate]);
}
