// Example: In App.js (or a higher-level component)
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AnimatedRoutes from "./AnimatedRoutes";
import { ThemeProvider } from "./common/ThemeSwitcherContext";


function App() {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    // Simulate async check (like auth)
    setTimeout(() => {
      setInitialLoadComplete(true);
    }, 100); // Adjust as needed
  }, []);

  return (
    <ThemeProvider>
      <div className="auth-wrapper">
        <div className="auth-inner">
          {initialLoadComplete ? <AnimatedRoutes /> : null}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
