// OrganizationMenu.tsx

import { Button, Dropdown, notification } from 'antd';
import { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { URL_API_V1 } from '../constants/global';
import { userAtom } from './Atoms';
import AxiosApiInstance from './Interceptors';
import type { MenuProps } from 'antd';

// Define TypeScript interfaces for clarity and type safety
interface Organization {
  uuid: string;
  slug: string;
  name: string;
}

interface UserProfile {
  uuid: string;
  last_login: string;
  email: string;
  is_active: boolean;
  is_verified: boolean;
  is_2fa_activated: boolean;
  default_organization_slug: string;
  name: string;
  organizations: Organization[];
}

const OrganizationMenu = () => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user, setUser] = useRecoilState(userAtom);
  const [menuItems, setMenuItems] = useState<MenuProps['items']>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  /**
   * Fetches the user's profile from the API and updates both the Recoil state and local state.
   */
  const getUserProfile = async () => {
    try {
      const response = await axiosApiInstance.get<UserProfile>(`${URL_API_V1}/profile`);
      if (response.status === 200) {
        const { default_organization_slug, uuid, organizations } = response.data;
        console.log("Profile response:", response.data); // Debug

        // Update Recoil state
        setUser((prevUser) => ({
          ...prevUser,
          uuid,
          organization: default_organization_slug,
          // Remove organizations from userAtom if not needed elsewhere
        }));

        // Update local state
        setOrganizations(organizations || []);
        console.log("Local organizations state set to:", organizations || []);
      } else {
        console.error('Unexpected response status:', response.status);
        notification.error({
          message: 'Failed to fetch profile',
          description: `Received status code ${response.status} from the server.`,
          duration: 3,
        });
      }
    } catch (error) {
      console.error('Exception in getUserProfile:', error);
      notification.error({
        message: 'Failed to fetch user profile.',
        description: 'An error occurred while retrieving your profile information.',
        duration: 3,
      });
    }
  };

  /**
   * Retrieves an organization object based on its slug from the local organizations state.
   * @param organization_slug - The slug of the organization to retrieve.
   * @returns The matching Organization object or undefined if not found.
   */
  const getOrganizationBySlug = (organization_slug: string): Organization | undefined => {
    console.log("Local organizations:", organizations);
    const selectedOrganization = organizations.find((org) => org.slug === organization_slug);
    console.log('Found Selected organization:', selectedOrganization);
    return selectedOrganization;
  };

  /**
   * Sets the default organization by updating the user's profile via a PATCH request.
   * @param organization_slug - The slug of the organization to set as default.
   */
  const setDefaultOrganization = async (organization_slug: string) => {
    const selectedOrganization = getOrganizationBySlug(organization_slug);

    if (!selectedOrganization) {
      console.error(`Organization with slug ${organization_slug} not found`);
      notification.error({
        message: `The organization '${organization_slug}' could not be found.`,
        duration: 2,
      });
      return;
    }

    const payload = {
      default_organization: selectedOrganization.uuid,
    };

    console.log("Patching profile with data:", payload);

    try {
      const response = await axiosApiInstance.patch(`${URL_API_V1}/profile/${user.uuid}/`, payload);
      if (response.status === 200) {
        notification.success({
          message: `The organization '${organization_slug}' has been set as default.`,
          duration: 2,
        });
        // Re-fetch user profile to update state
        await getUserProfile();
      } else {
        console.error('Unexpected response status during patch:', response.status);
        notification.error({
          message: 'Update Failed',
          description: 'An unexpected error occurred while updating the default organization.',
          duration: 3,
        });
      }
    } catch (error) {
      console.error('Error updating default organization:', error);
      notification.error({
        message: 'Update Error',
        description: 'An error occurred while updating the default organization.',
        duration: 3,
      });
    }
  };

  /**
   * Handles menu item clicks by setting the default organization.
   * @param param0 - Contains the key of the clicked menu item.
   */
  const handleMenuClick = ({ key }: { key: string }) => {
    setDefaultOrganization(key);
    setUser((prevUser) => ({ ...prevUser, organization: key }));
  };

  /**
   * Generates the menu items based on the local organizations state.
   */
  const generateMenuItems = () => {
    if (Array.isArray(organizations) && organizations.length > 0) {
      const items = organizations.map((org) => ({
        key: org.slug,
        label: org.name || org.slug || 'Unnamed Organization',
      }));
      setMenuItems(items);
      console.log('Menu items set to:', items);
    } else {
      setMenuItems([]);
      console.log('No organizations available to set as menu items.');
    }
  };

  // Fetch user profile when the component mounts
  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on mount

  // Update menu items whenever the local organizations state changes
  useEffect(() => {
    console.log('Local organizations updated:', organizations);
    generateMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  return (
    <div>
      <Dropdown menu={{ items: menuItems, onClick: handleMenuClick }}>
        <Button>
          {user.organization || 'Organization'} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default OrganizationMenu;