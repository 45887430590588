import { Modal, Input, Button } from 'antd';
import { useState } from 'react';
import styles from './ModalAddOrganization.module.scss';

export default function ModalDeleteOrganization(props) {
  const [confirmationText, setConfirmationText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const cancelDeleteOrganization = () => {
    props.hide();
    setConfirmationText('');
  };

  const deleteOrganization = async () => {
    setIsDeleting(true);
    await props.deleteOrganization();
    setIsDeleting(false);
    props.hide();
    setConfirmationText('');
  };

  const handleInputChange = (e) => {
    setConfirmationText(e.target.value);
  };

  return (
    <Modal
      className={styles.Organizations}
      title="Confirmation"
      open={props.isShowing}
      onOk={deleteOrganization}
      onCancel={cancelDeleteOrganization}
      footer={[
        <Button key="cancel" onClick={cancelDeleteOrganization}>
          Cancel
        </Button>,
        <Button
          key="delete"
          type="primary"
          danger
          onClick={deleteOrganization}
          disabled={confirmationText !== 'DELETE'}
          loading={isDeleting}
        >
          Delete
        </Button>,
      ]}
    >
      <p>
        Are you sure you want to delete the organization: <b>{props.selectedOrganization?.name}</b>?
      </p>
      <p>ALL data will permanently be deleted from this organization.</p>
      <p>Please type <b>DELETE</b> to confirm:</p>
      <Input
        placeholder="Type DELETE to confirm"
        value={confirmationText}
        onChange={handleInputChange}
      />
    </Modal>
  );
}