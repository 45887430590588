import { Alert, Button, Card, Col, Divider, Layout, Row, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1, reCAPTCHAKEY } from "../../constants/global";
import { TWOFA_LOGIN_PATH } from "../../constants/urls";

const { Content } = Layout;

function TwoFASetupInner() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  let navigate = useNavigate();
  const { axiosApiInstance } = AxiosApiInstance();

  const [errorMessage, setErrorMessage] = useState("");

  const [qrCode, setQrCode] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [emergencyCodes, setEmergencyCodes] = useState([]);


  const setup2FA = useCallback((recaptchaToken) => {
    let data = { response: recaptchaToken };
    axiosApiInstance
      .post(`${URL_API_V1}/auth/totp`, data)
      .then((response) => {
        if (response && response.status === 201) {
          console.log(response.data);
          const result: any = response.data;
          setQrCode(result["otpauth"]);
        } else {
          setErrorMessage("An error has occurred.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 400) {
          if ("errors" in err.response.data) console.log(err.response.data["errors"][0]);
          setErrorMessage(err.response.data["errors"][0]);
        }
      });
  },[axiosApiInstance]);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleLoad2FAReCaptchaVerify = useCallback(async () => {
    let token = ''

    if(reCAPTCHAKEY) {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      token = await executeRecaptcha("login");
    }
    setup2FA(token);
  }, [setup2FA, executeRecaptcha]);

  const setupEmergencyCodes2FA = useCallback((recaptchaToken) => {
    let data = { response: recaptchaToken };
    axiosApiInstance
      .post(`${URL_API_V1}/auth/totp/static`, data)
      .then((response) => {
        console.log(response);
        if (response && response.status === 201) {
          const result: any = response.data;
          setEmergencyCodes(result["tokens"]);
        } else {
          setErrorMessage("An error has occurred.");
        }
      })
      .catch((err) => {
        console.log("error in setupEmergencyCodes2FA");
        console.log(err);
      });
  }, [axiosApiInstance]);

  const handleLoadEmergencyCodesReCaptchaVerify = useCallback(async () => {

    let token = ''
    
    if(reCAPTCHAKEY) {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      token = await executeRecaptcha("login");
    }
    setupEmergencyCodes2FA(token);
  }, [setupEmergencyCodes2FA, executeRecaptcha]);



  useEffect(() => {
    handleLoad2FAReCaptchaVerify();
    handleLoadEmergencyCodesReCaptchaVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (qrCode.length > 0) {
      let secretIndexStart = qrCode.indexOf("secret=");
      let secretIndexEnd = qrCode.indexOf("&algorithm=");
      let secretCode = qrCode.substring(secretIndexStart + 7, secretIndexEnd);
      setSecretCode(secretCode);
    } else {
      setSecretCode("");
    }
  }, [qrCode]);

  const emergencyCodesList = (
    <ul>
      {emergencyCodes.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );

  const loginButton = (
    <div style={{ textAlign: "center", margin: "5px" }}>
      <Button htmlType="button" type="primary" onClick={() => navigate(TWOFA_LOGIN_PATH)}>
        Login
      </Button>
    </div>
  );

  const instructions = (
    <div>
      <Divider plain orientation="left">
        Manual setup
      </Divider>
      <p>Secret code:</p>
      <h5>
        <Tag color="gray">{secretCode}</Tag>
      </h5>
      <div style={{ textAlign: "center" }}>
        <Divider plain orientation="left">
          QRCode
        </Divider>
        <QRCode name="QRCode" value={qrCode} />
      </div>
      <div style={{ textAlign: "left" }}>
        <Divider plain orientation="left">
          Emergency backup codes
        </Divider>
        Use the emergency backup code in case you lose your smartphone or the authenticator which provides the 6-digit
        tokens.
        <br />
        The emergency can help you authenticate and reset the 2FA by disabling and re-enabling. Each emergency token can
        be used only once.
        {emergencyCodesList}
      </div>
      {loginButton}
    </div>
  );

  return (
    <div className="" style={{}}>
      <Card title="Activating 2FA">
        <Layout>
          <Content>
            <Row>
              <Col>
                Steps
                <ol>
                  <li>
                    Install any authenticator tool on your Smartphone for two-faction authentication (2FA) such as:
                    Google Authenticator, Authy or Microsoft Authenticator
                  </li>
                  <li>
                    Scan the QR code below with your smartphone camera, or type in the displayed secret code manually.
                  </li>
                  <li>The next time you login, provide the 6 digit token displayed from the authenticator.</li>
                  <li>
                    Please note that you will not be able to retrieve the QRCode or Secret code later, so keep them
                    safetly.
                  </li>
                  <li>
                    Take note of the emergency backup codes can help you authenticate in case you lose your device with
                    authenticator for 2FA. Note that the emergency backup codes are only valid for single use.
                  </li>
                </ol>
                <div style={{ textAlign: "center", margin: "5px" }}>
                  {!errorMessage && (
                    <Button
                      htmlType="button"
                      type="primary"
                      onClick={() => {
                        handleLoad2FAReCaptchaVerify();
                        handleLoadEmergencyCodesReCaptchaVerify();
                      }}
                    >
                      Activate 2FA
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            {qrCode && emergencyCodes && instructions}
            <Row>
              <Col span={24} offset={0}>
                {errorMessage && loginButton}
                {errorMessage && <Alert style={{ textAlign: "center" }} message={errorMessage} type="error" showIcon />}
              </Col>
            </Row>
            {/* <Row>
              <Col span={16} offset={4}>
                {status && <Alert message={status} type="success" showIcon />}
              </Col>
            </Row> */}
          </Content>
        </Layout>
      </Card>
    </div>
  );
}

export const TwoFASetup = () => <TwoFASetupInner />;
