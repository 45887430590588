import { Button, Card, Form, Input, Result, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosApiInstance from "../../common/Interceptors";
import RenderErrorMessages from "../../common/RenderErrorMessages";
import { URL_API_V1 } from "../../constants/global";
import styles from "./ChangePassword.module.scss";

const ChangePassword = (props) => {
  const { axiosApiInstance } = AxiosApiInstance();
  let { token } = useParams();

  const STATUS_CODES = {
    VALIDATING: "VALIDATING",
    TOKEN_NOT_VALID: "TOKEN_NOT_VALID",
    TOKEN_VALID: "TOKEN_VALID",
    PASSWORD_UPDATED: "PASSWORD_UPDATED",
    UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  };

  const [form] = Form.useForm();
  const [currentStatus, setCurrentStatus] = useState(STATUS_CODES.VALIDATING);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const routeChange = () => {
    let path = `/login`;
    navigate(path);
  };

  const onFinish = (values) => {
    const data = {
      newPassword: values.newPassword,
      newPasswordConfirmation: values.newPasswordConfirmation,
    };

    axiosApiInstance
      .post(`${URL_API_V1}/auth/password/reset/step/2/token/${token}`, data)
      .then((resp) => {
        if (resp.status === 200) {
          setMessage("The password has been successfully updated.");
          setCurrentStatus(STATUS_CODES.PASSWORD_UPDATED);
        } else {
          setMessage("An unexpected error has occured. The password could not be changed.");
          setCurrentStatus(STATUS_CODES.UNEXPECTED_ERROR);
        }
      })
      .catch((err) => {
        RenderErrorMessages(err.response.data["reason"], form);
        setCurrentStatus(STATUS_CODES.UNEXPECTED_ERROR);
      });
  };

  const tokenValidation = useCallback(() => {
    axiosApiInstance
      .get(`${URL_API_V1}/auth/password/reset/step/1/token/${token}`)
      .then((resp) => {
        if (resp.status === 200) {
          setCurrentStatus(STATUS_CODES.TOKEN_VALID);
        } else {
          setCurrentStatus(STATUS_CODES.TOKEN_NOT_VALID);
        }
      })
      .catch((err) => {
        setCurrentStatus(STATUS_CODES.TOKEN_NOT_VALID);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    tokenValidation();
  }, [tokenValidation]);

  return (
    <div className={styles.ChangePassword}>
      {/* <div>Current status: {currentStatus}</div> */}
      {currentStatus === STATUS_CODES.TOKEN_NOT_VALID && (
        <div>
          Provided token has expired or is not valid. Please submit a <a href="/forgot-credentials">new request</a> for
          password change.
        </div>
      )}
      {currentStatus === STATUS_CODES.VALIDATING && <div>Please wait..validating</div>}
      {[STATUS_CODES.TOKEN_VALID, STATUS_CODES.UNEXPECTED_ERROR].includes(currentStatus) && (
        // <Container className="p-3">
        <Space direction="vertical">
          <Card title="Enter new password" style={{ margin: "25px" }}>
            <Form
              layout="horizontal"
              labelCol={{
                span: 12,
              }}
              // wrapperCol={{
              //   span: 16,
              // }}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "New password field is missing",
                  },
                ]}
              >
                <Input type="password" disabled={message?.length > 0} />
              </Form.Item>
              <Form.Item
                name="newPasswordConfirmation"
                label="New Password Confirmation"
                rules={[
                  {
                    required: true,
                    message: "New password confirmation field is missing.",
                  },
                ]}
              >
                <Input type="password" disabled={message?.length > 0} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={message?.length > 0}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      )}
      {currentStatus === STATUS_CODES.PASSWORD_UPDATED && message?.length > 0 && (
        <>
          <Result
            status="success"
            title={message}
            extra={
              <Button key="home" type="primary" onClick={routeChange}>
                Go Back
              </Button>
            }
          />
        </>
      )}
    </div>
  );
};

export default ChangePassword;
