
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Utils } from 'common/2FAUtils';
import { URL_API_V1 } from 'constants/global';
import { jwtDecode } from 'jwt-decode';
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import { useNavigate } from 'react-router-dom';

export const SocialLogin = (props) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("Processing authentication...");
  const [hasError, setHasError] = useState(false);
  const [user, setUser] = useRecoilState(userAtom);

  const backHome = (
    <div className="text-center">
      <a href="/" className="btn btn-primary">Back to Home</a>
    </div>
  )

  const handleGoogleAuthentication = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const storedState = localStorage.getItem('oauthState');
    console.log(`state: ${state}, storedState: ${storedState}`)
    if (state !== storedState) {
      console.error('State value does not match');
      return;
    }
    const api_url = `${URL_API_V1}/google/callback?code=${code}&state=${state}`
    apiCallback(api_url)
  }

  const handleGithubAuthentication = () => {
    console.log("handleAuthentication")
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const storedState = localStorage.getItem('oauthState');
    if (state !== storedState) {
      console.error('State value does not match');
      return;
    }
    apiCallback(`${URL_API_V1}/github/callback?code=${code}&state=${state}`)
  }

  const handleAzureAuthentication = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    apiCallback(`${URL_API_V1}/azure/callback?code=${code}&state=${state}`)
  }

  const apiCallback = (callback_url) => {
    axios.get(`${callback_url}`)
    .then((response) => {
      console.log("response", response)
      //console.log("getPiiFields:" + JSON.stringify(responseData));
      if (response && response.status === 200) {
        const data: any = response.data;
        let default_organization = data['default_organization']
        let jwt_decoded = jwtDecode(data.token);
        const is_2fa_authenticated = Utils.isJWT2FAAuthenticated(jwt_decoded);
        setUser({ ...data, 
          is_authenticated: true, 
          is_2fa_authenticated, 
          organization: default_organization, 
          organizations: jwt_decoded['organizations'] 
        });
        navigate('/dashboard', { replace: true });
      } else {
        setHasError(true);
        setMessage("An error occurred while processing your request. Please try again later.");
      }
    }).catch((error) => {
      console.log("error", error)
      setHasError(true);
      setMessage(""+ error.response.data['error']);
    })
  }
  
  useEffect(() => {
    console.log("Callback props: ", props.name);
    if(props.name === "google") {
      handleGoogleAuthentication();
    } else if (props.name === "azure") {
      handleAzureAuthentication();
    } else if (props.name === "github") {
      handleGithubAuthentication();
    }
  }, []);

  return (
    <>
    <div style={{textAlign:"center"}}>{message}</div>
    { hasError && <div style={{textAlign:"center"}}>{backHome}</div> }
    </>
  );
};
