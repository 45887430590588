import { Button, Modal, Form, Layout, Row, Col, Input, Select, notification } from 'antd';
import AxiosApiInstance from '../../common/Interceptors';
import { URL_API_V1 } from '../../constants/global';
import React, { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';
import { userAtom } from '../../common/Atoms';

import { Content } from 'antd/lib/layout/layout';

interface IEventType {
  id: string;
  name: string;
}
interface IFieldType {
  id: string;
  name: string;
}

const { Option } = Select;

const AddFieldsModal = (props) => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [eventTypes, setEventTypes] = useState<IEventType[]>([]);
  const [fieldTypes, setFieldTypes] = useState<IFieldType[]>([]);

  useEffect(() => {
    console.log('props: ' + JSON.stringify(props));
    form.resetFields();
    getEventTypes();
    getFieldTypes();
  }, []);

  const handleOk = (form) => {

    if (!user.organization) {
      notification.error({
        message: "Organization Not Selected",
        description: "Please select an organization before fetching devices.",
        key: "organization-not-selected", // Unique key to prevent duplicate notifications
      });
      return;
    }

    console.log('submitting..');
    form.validateFields().then((values) => {
      const data = {
        name: values['name'],
        event_type: values['eventType'],
        type: values['fieldType'],
      };

      axiosApiInstance.post(`${URL_API_V1}/r/${user.organization}/events/fields/`,data).then((response) => {
        if (response.status === 201) {
          props.getAllFields();
          hideAndReset(form);
        } else {
          notification.error({ message: `Something unexpected happened, the field '${data["name"]}' was not added.`, duration: 2.5 });
        }
      });
    });
  };

  const hideAndReset = (form) => {
    setVisible(false);
    form.resetFields();
    props.hide();
  }

  const handleCancel = (form) => {
    hideAndReset(form);
  };

  function getEventTypes() {
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/event-types/`)
      .then((response) => {
        let results: any = [];
        if (response.status === 200) {
          results = response.data;
          if ('entries' in results) {
            results = results['entries'];
          }
        }
        console.log('setting the field');
        setEventTypes(results);
      })
      .catch((exception) => {
        console.log('exception getEventTypes..' + exception);
      });
  }

  function getFieldTypes() {
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/field-types/`)
      .then((response) => {
        let results: any = [];
        if (response.status === 200) {
          results = response.data;
          if ('entries' in results) {
            results = results['entries'];
          }
        }
        console.log('setting the field');
        setFieldTypes(results);
      })
      .catch((exception) => {
        console.log('exception getEventTypes..' + exception);
      });
  }

  return (
    <div>
      <Modal
        title="Add Field"
        centered
        maskClosable={false}
        open={props.isShowing}
        width={500}
        onOk={() => handleOk(form)}
        onCancel={() => handleCancel(form)}
        footer={[
          <Button key="back" onClick={() => handleCancel(form)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleOk(form)}>
            Add
          </Button>,
        ]}
      >
        <Layout>
          <Content>
            <Form form={form} onFinish={handleOk}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item label="Name" name="name" required>
                    <Input placeholder="Field name" required />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Event Type" name="eventType" required>
                    <Select style={{ width: '100%' }}>
                      {eventTypes.map((entry) => (
                        <Option value={entry['id']}>{entry['name']}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Field Type" name="fieldType" required>
                    <Select style={{ width: '100%' }}>
                      {fieldTypes.map((entry) => (
                        <Option value={entry['id']}>{entry['name']}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Content>
          {/* <Footer>{hasErrors && hasErrors.length > 0 && <Alert type="error" message={hasErrors} banner />}</Footer> */}
        </Layout>
      </Modal>
    </div>
  );
};

export default AddFieldsModal;
