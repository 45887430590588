// src/AnimatedRoutes.js

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useRef } from "react";

import ForgotCredentials from "components/ForgotCredentials/ForgotCredentials";
import { SocialLogin } from "components/SocialLogin/SocialLogin";
import BasicLayout from "./components/BasicLayout/BasicLayout";
import Dashboard from "./components/Dashboard/Dashboard";
import Devices from "./components/Devices/Devices";
import EventsV3 from "./components/Events/EventsV3";
import Login from "./components/Login/Login";
import Organizations from "./components/Organizations/Organizations";
import { Profile } from "./components/Profile/Profile";
import PublicLayout from "./components/PublicLayout/PublicLayout";
import RedirectToDashboardOrLogin from "./components/RedirectToDashboardOrLogin/RedirectToDashboardOrLogin";
import Register from "./components/Register/Register";
import Settings from "./components/Settings/Settings";
import TwoFALogin from './components/TwoFA/TwoFALogin';
import { TwoFASetup } from './components/TwoFA/TwoFASetup';
import ChangePassword from './components/ChangePassword/ChangePassword';
import AcceptInvitation from './components/AcceptInvitation/AcceptInvitation';

import Users from "./components/Users/Users";

import PrivateRoute from "./common/PrivateRoute";
import { EventsTableProvider } from "./contexts/EventsTableContext";
import { PIIFieldsProvider } from "./contexts/PIIFieldsContext";
import { TimeRangeProvider } from "./contexts/TimeRangeContext";
import { DeviceChartsProvider } from "./contexts/DeviceChartsContext";
import { DeviceProvider } from "./contexts/DeviceContext";

const AnimatedRoutes = () => {
  const location = useLocation();
  const nodeRef = useRef(null);

  return (
    // <TransitionGroup>
      // <CSSTransition
      //   key={location.key}
      //   classNames="view"
      //   timeout={500}
      //   nodeRef={nodeRef}
      //   appear // Add appear prop here
      // >
        <div ref={nodeRef} className="route-wrapper">
          <Routes location={location}>
            <Route path="/" element={<RedirectToDashboardOrLogin />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute
                  element={() => (
                    <BasicLayout>
                      <Dashboard />
                    </BasicLayout>
                  )}
                />
              }
            />
            <Route
              path="/events"
              element={
                <PrivateRoute
                  element={() => (
                    <BasicLayout>
                      <PIIFieldsProvider>
                        <EventsTableProvider>
                          <TimeRangeProvider>
                            <EventsV3 />
                          </TimeRangeProvider>
                        </EventsTableProvider>
                      </PIIFieldsProvider>
                    </BasicLayout>
                  )}
                />
              }
            />
            <Route
              path="/devices"
              element={
                <PrivateRoute
                  element={() => (
                    <BasicLayout>
                      <PIIFieldsProvider>
                        <DeviceChartsProvider>
                          <DeviceProvider>
                            <Devices />
                          </DeviceProvider>
                        </DeviceChartsProvider>
                      </PIIFieldsProvider>
                    </BasicLayout>
                  )}
                />
              }
            />
            <Route
              path="/app-settings/*"
              element={
                <PrivateRoute
                  element={() => (
                    <BasicLayout>
                      <Settings />
                    </BasicLayout>
                  )}
                />
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute
                  element={() => (
                    <BasicLayout>
                      <Profile />
                    </BasicLayout>
                  )}
                />
              }
            />
            <Route
              path="/organizations"
              element={
                <PrivateRoute
                  element={() => (
                    <BasicLayout>
                      <Organizations />
                    </BasicLayout>
                  )}
                />
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute
                  element={() => (
                    <BasicLayout>
                      <Users />
                    </BasicLayout>
                  )}
                />
              }
            />
            <Route
              path="/login"
              element={
                <PublicLayout>
                  <Login />
                </PublicLayout>
              }
            />
            <Route
              path="/register"
              element={
                <PublicLayout>
                  <Register />
                </PublicLayout>
              }
            />
            <Route
              path="/forgot-credentials"
              element={
                <PublicLayout>
                  <ForgotCredentials />
                </PublicLayout>
              }
            />
            <Route
              path="/google/callback"
              element={
                <PublicLayout>
                  <SocialLogin name="google" />
                </PublicLayout>
              }
            />
            <Route
              path="/azure/callback"
              element={
                <PublicLayout>
                  <SocialLogin name="azure" />
                </PublicLayout>
              }
            />
            <Route
              path="/github/callback"
              element={
                <PublicLayout>
                  <SocialLogin name="github" />
                </PublicLayout>
              }
            />
      
            <Route
              path="/2fa/login"
              element={
                <PublicLayout>
                  <TwoFALogin />
                </PublicLayout>
              }
            />
            
            <Route
              path="/2fa/setup"
              element={
                <PublicLayout>
                  <TwoFASetup />
                </PublicLayout>
              }
            />
            
            <Route
              path="/reset-password/:token"
              element={
                <PublicLayout>
                  <ChangePassword />
                </PublicLayout>
              }
            />
            
            <Route
              path="/r/:slug/accept-invitation/:token"
              element={
                <PublicLayout>
                  <AcceptInvitation />
                </PublicLayout>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      // </CSSTransition>
    // </TransitionGroup>
  );
};

export default AnimatedRoutes;